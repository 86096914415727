import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import useSiteSettings from '~utils/useSiteSettings'
import Ticker from '~components/Ticker'
import { Envelope } from '~components/Svg'
import KlaviyoForm from '~components/KlaviyoForm'
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import { useSiteState } from '~context/siteContext'
import Cookies from 'js-cookie'

const SubscribePopup = ({ className }) => {
	const [formOpen, setFormOpen] = useState(false)
	const { subscribeMessage } = useSiteSettings()
	const [siteState, setSiteState] = useSiteState()

	useEffect(() => {
		if(!Cookies.get('subscribePopUpClosed') && Cookies.get('cookiesPopUpClosed')){
			setTimeout(() => {
				setFormOpen(true)
			}, 1000)
    }
	}, [siteState.cookiesPopUp])

	const close = () => {
		Cookies.set('subscribePopUpClosed', true, { expires: 7 })
		setFormOpen(false)
	}

	// useEffect(() => {
	// 	window.setTimeout(() => setFormOpen(true), 2000)
	// }, [])
	return (
		<Wrap>
			<SlideDown>
			{formOpen &&
				<Form closeForm={() => close()} />
			}
			</SlideDown>
		</Wrap>
		
	)
}

const Wrap = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
`

const Form = styled(KlaviyoForm)`
	
`

const StyledTicker = styled(Ticker)`
  height: 100px;
  border-top: 1px solid var(--black);
  border-bottom: 1px solid var(--black);
	> span {
		display: flex;
		align-items: center;
	}
  ${mobile}{
    height: 61px;
  }
`

const TickerIcon = styled(Envelope)`
	margin: 0 30px;
	width: 47px;
	${mobile}{
		margin: 0 20px;
    width: 32px;
	}
`

SubscribePopup.propTypes = {
	className: PropTypes.string
}

export default SubscribePopup