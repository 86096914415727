import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { sentenceCase } from "change-case";
import { ChunkyArrow } from '~components/Svg'
import Button from '~components/Button'

const ContactForm = ({ className, formName, placeholder, onSuccess, emailDestination }) => {
  
  const [values, setValues] = useState({})
  const [errors, setErrors] = useState([])
  const [success, setSuccess] = useState(false)
  const [sendError, setSendError] = useState(false)

  const validate = e => {
    e.preventDefault()
    const newErrors = []
    if(!values.searchItem) newErrors.push({
      field: 'message',
      message: `Oops looks like you haven't typed anything!`
    })

    if(newErrors.length === 0){
      submit()
    }
    else{
      setErrors(newErrors)
    }
  }

  const submit = () => {
		const msg = {
			to: emailDestination,
			from: {
				email: 'noreply@formwork.host',
				name: 'Homeroom Website'
			},
			subject: 'New Feedback',
			template_id: 'd-699827f9c4da406b84cb0e6f067d9200',
			dynamic_template_data: {
				subject: 'New Feedback',
				headline: 'What are you looking for?',
				fields: Object.keys(values).map(key =>({label: sentenceCase(key), value: values[key]}))
			},
		}
		axios.post('/api/trigger-sendgrid', msg)
			.then(res => {
				console.log(res)
				setSuccess(true)
			})
			.catch(err => {
        console.log(err)
        setSendError(true)
      }
		)
	}


	useEffect(() => {
		if(success){
			onSuccess()
		}
	}, [success])

  const errorFields = errors.map(error => error.field)

  return (
		<div>
      <div className={className} css={css`
          position: relative;
      `}>
        <Form method="POST" name={formName} noValidate onSubmit={e => validate(e)} css={css`
            opacity: ${success ? '0' : '1'};
            pointer-events: ${success ? 'none' : 'all'};
          `}>
          <Input
						className='highlight'
            type="text"
            placeholder={placeholder}
            id="searchItem"
            name="searchItem"
            onChange={e => setValues({...values, searchItem: e.target.value})}
          />
          {errors?.length > 0 &&
            <Errors>
              {errors.map(error => <p className="tiny">{error.message}</p>)}
            </Errors>
          }
          <Submit onClick={() => null} type="submit" className='caps' borderButton>
            Submit
						<Arrow />
          </Submit>
        </Form>
      </div>
		</div>
  )
}

const Success = styled.h5`
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s 0.3s;
`

const Form = styled.form`
  transition: opacity 0.3s;
  input, textarea{
    display: block;
  }
`
const Input = styled.input`
	background-color: transparent;
	color: var(--black);
	width: 100%;
	border-bottom: none;
	background-image: linear-gradient(to right, black 20%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 5px 1px;
  background-repeat: repeat-x;
  padding-bottom: 4px;
	margin-bottom: 19px;
`
const Errors = styled.div`
  grid-column: span 2;
  margin-top: -14px;
  margin-bottom: 20px;
  color: var(--red);
`

const Submit = styled(Button)`
	display: inline-flex;
	align-items: center;
	margin-bottom: 22px;
`
const Arrow = styled(ChunkyArrow)`
	width: 24px;
	margin-left: 7px;
	position: relative;
	top: 2px;
`

ContactForm.propTypes = {
  formName: PropTypes.string.isRequired,
  className: PropTypes.string,
  successMessage: PropTypes.array,
	onSuccess: PropTypes.func,
}

export default ContactForm
