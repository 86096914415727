import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import useSiteSettings from '~utils/useSiteSettings'
import Ticker from '~components/Ticker'
import { Envelope } from '~components/Svg'
import KlaviyoForm from '~components/KlaviyoForm'
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

const SubscribeTicker = ({ className }) => {
	const [formOpen, setFormOpen] = useState(false)
	const { subscribeMessage } = useSiteSettings()

	return (
		<Wrap>
			<SlideDown>
			{formOpen &&
				<Form closeForm={() => setFormOpen(false)} />
			}
			</SlideDown>
			<button onClick={() => setFormOpen(!formOpen)}>
				<StyledTicker className={className} duration={`400s`}>
					<span className='h1'>{subscribeMessage}</span>
					<TickerIcon />
				</StyledTicker>
			</button>
		</Wrap>
		
	)
}

const Wrap = styled.div`
	
`

const Form = styled(KlaviyoForm)`
	
`

const StyledTicker = styled(Ticker)`
  height: 100px;
  border-top: 1px solid var(--black);
  border-bottom: 1px solid var(--black);
	> span {
		display: flex;
		align-items: center;
	}
  ${mobile}{
    height: 61px;
  }
`

const TickerIcon = styled(Envelope)`
	margin: 0 30px;
	width: 47px;
	${mobile}{
		margin: 0 20px;
    width: 32px;
	}
`

SubscribeTicker.propTypes = {
	className: PropTypes.string
}

export default SubscribeTicker