import React, { useEffect, useState } from 'react'
import { Link, navigate, graphql, useStaticQuery } from 'gatsby'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Logo, SearchIcon, Close } from '~components/Svg'
import DataViewer from '~utils/DataViewer'
import { mobile, tablet } from '~styles/global'
import { Squash as Hamburger } from 'hamburger-react'
import Menu from '~components/Menu'
import { useSiteState } from '~context/siteContext'
import MobileMenu from '~components/MobileMenu'
import { useLocation } from '@reach/router'
import SearchOverlay from '~components/SearchOverlay'
import SubscribePopup from './SubscribePopup'

const Header = () => {

	const location = useLocation()

  const menuData = useStaticQuery(graphql`
    query navQuery {
      sanityNavigation(_id: {regex: "/(drafts\\\\.)?navigation/"}) {
        ...mainMenu
      }
    }
  `)
  const {
    menuItems, 
		featuredCollections,
		featuredCollectionsBelow,
    featuredBrands, 
		featuredInclusivity,
		featuredValues,
		featuredInterest,
    featuredBrandEdits, 
    featuredArticles, 
    featuredColumns
  } = menuData.sanityNavigation

	const [siteState, setSiteState] = useSiteState()

	const openMenu = (viewType) => {
		setSiteState(prevState => ({
			...prevState,
			searchOpen: false,
			menuView: viewType,
		}))
		if (siteState.menuView === viewType && siteState.menuOpen){
			setSiteState(prevState => ({
				...prevState,
				menuOpen: false,
			}))
		} else {
			setSiteState(prevState => ({
				...prevState,
				menuOpen: true,
				searchOpen: false,
			}))
		}
	}

	useEffect(() => {
		if(!siteState.menuOpen){
			setTimeout(()=> {
				setSiteState(prevState => ({
					...prevState,
					menuView: undefined,
				}))
			}, 400)
		}
	}, [siteState.menuOpen])

	useEffect(() => {
		setSiteState(prevState => ({
			...prevState,
			// menuView: undefined,
			menuOpen: false,
		}))
	}, [location])

  return(
    <>
    <Wrap searchOpen={siteState.searchOpen}>
			<SubscribePopup />
      <Hamburger
        rounded
        distance="lg"
        size={20}
        toggled={siteState.menuOpen} 
        toggle={() => setSiteState(prevState => ({
					...prevState,
					menuOpen: !siteState.menuOpen,
					searchOpen: false
				}))}
				css={css`display:none;`}
      />
      <nav className="caps">
        <NavButton onClick={()=> openMenu('directory')} active={siteState.menuView  === 'directory'}>Directory</NavButton>
        {menuItems.map(item => 
          <NavButton 
						key={item._key}
						active={siteState.menuView  === item.title.toLowerCase()}
						onClick={()=> openMenu((item.title).toLowerCase())}
					>
            {item.title}
          </NavButton>
        )}
				<NavButton onClick={()=> openMenu('focus')} active={siteState.menuView  === 'focus'}>Focus</NavButton>
        <NavButton onClick={()=> openMenu('journal')} active={siteState.menuView  === 'journal'}>Journal</NavButton>
      </nav>
      <LogoButton to="/">
        <Logo />
      </LogoButton>
      <nav className="caps">
        <NavLink to="/about" active={location.pathname === '/about'}>About</NavLink>
        <SearchButton onClick={() => setSiteState(prevState => ({
					...prevState,
					menuOpen: false,
					searchOpen: !siteState.searchOpen,
				}))}>
					{siteState.searchOpen ?
						<>
							Close <CloseIcon />
						</>
						:
						<>
							Search <SearchIcon />
						</>
					}
				</SearchButton>
      </nav>
      <MobileSearch searchOpen={siteState.searchOpen} onClick={() => setSiteState(prevState => ({
					...prevState,
					menuOpen: false,
					searchOpen: !siteState.searchOpen,
				}))}>
				{siteState.searchOpen ? 
					<Hamburger
						rounded
						distance="lg"
						size={20}
						toggled={true} 
					/>
				: <SearchIcon />
				}
			</MobileSearch>
    </Wrap>
		<MainMenu 
			view={siteState.menuView} 
			menuData={menuData.sanityNavigation}
			css={css`
				transform: ${siteState.menuOpen ? 'translateY(0px)' : 'translateY(calc(-100% - 50px))'};
			`}
		/>
		<MainMenuMobile
			menuData={{
			menuItems,
			featuredBrands, 
			featuredBrandEdits, 
			featuredArticles, 
			featuredColumns,
			featuredInclusivity,
			featuredValues,
			featuredCollections,
			featuredCollectionsBelow,
			featuredInterest}}
			css={css`
				transform: ${siteState.menuOpen ? 'translateY(0px)' : 'translateY(calc(-100% - 50px))'};
			`}
		/>
		{ siteState.searchOpen &&
			<Search />
		}
    <DataViewer data={menuData} name="menuData"/>
    </>
  )
}

const NavButton = styled.button`
	font-weight: ${props => props.active ? 700 : 400};
`

const NavLink = styled(Link)`
	font-weight: ${props => props.active ? 700 : 400};
`

const Wrap = styled('header')`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr 136px 1fr;
  column-gap: 40px;
  border-bottom: 1px solid ${props => props.searchOpen ? '#000000' : 'var(--red)'};
  height: 50px;
  background: ${props => props.searchOpen ? 'var(--pink)' : 'var(--white)'};
  z-index: 30;
  align-items: center;
  padding: 0 40px;
  ${mobile}{
    padding: 0;
    column-gap: 15px;
  }
  nav{
    font-weight: 500;
    display: flex;
    ${mobile}{
      display: none;
    }
    button, a{
      padding-right: 30px;
      display: flex;
      align-items: center;
      transition: color 0.3s;
      &:hover{
        color: var(--red);
      }
      svg{
        width: 22px;
        display: block;
        margin-left: 10px;
      }
      &:last-child{
        padding-right: 0;
      }
      ${tablet}{
        padding-right: 20px;
      }
    }
    &:last-of-type{
      justify-content: flex-end;
    }
  }
	.hamburger-react{
    > div:nth-child(2){
      div{
        width: 15px !important;
      }
    }
    display: none;
    ${mobile}{
      display: block;
    }
  }
`

const LogoButton = styled(Link)`
  svg{
    width: 136px;
    display: block;
  }
`
const MainMenu = styled(Menu)`
	position: fixed;
	left: 0;
	right: 0;
	top: 50px;
	transition: transform 0.6s;
	z-index: 3;
	background: var(--white);
	${mobile}{
		display: none;
	}
`
const MainMenuMobile = styled(MobileMenu)`
	position: fixed;
	transition: transform 0.6s;
	top: 50px;
	z-index: 3;
	right: 0;
  left: 0;
	bottom: 0;
	background: var(--white);
	display: none;
	overflow: scroll;
	${mobile}{
		display: block;
	}
`

const MobileSearch = styled.button`
  display: none;
  margin-left: auto;
  padding: ${props => props.searchOpen ? '0' : '8px 15px'};
  svg{
    display: block;
    width: 20px;
  }
  ${mobile}{
    display: block
  }
`

const Search = styled(SearchOverlay)`
	position: fixed;
	left: 0;
	right: 0;
	top: 50px;
	/* transition: transform 0.6s; */
	z-index: 10;
	background: var(--pink);
`

const SearchButton = styled.button`
	width: 85px;
	justify-content: right;
`

const CloseIcon = styled(Close)`
	width: 15px !important;
`

export default Header
