import React from 'react'
import { css } from "@emotion/react"
import RichText from './RichText'
import Image from './Image'
import styled from '@emotion/styled'
import { mobile } from "~styles/global"

const Figure = ({ className, content }) => {
  return(
    <FigureContainer className={className}>
      <div>
        <FigureImage image={content} key={content.asset?._id}/>
        <Figurecaption className="highlight-s">
          <RichText content={content.caption}/>
        </Figurecaption>
      </div>
    </FigureContainer>
  )
}

const FigureContainer = styled.figure`
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  display: grid;
  align-items: center;
  height: 100%;
`
const Figurecaption = styled.figcaption`
  min-height: 18px;
  ${mobile}{
    margin-bottom: 4px;
  }
`
const FigureImage = styled(Image)`
  margin-bottom: 10px;
  ${mobile}{
    margin-bottom: 4px;
  }
`
export default Figure
