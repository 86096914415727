import { Link } from "gatsby"
import React, { useEffect } from "react"
import styled from '@emotion/styled'
import {
  connectStateResults,
  Highlight,
  connectInfiniteHits,
  Index,
  Snippet,
} from "react-instantsearch-dom"
import Section from '~components/Section'
import { mobile, tablet } from "~styles/global"
import Button from '~components/Button'
import BrandTile from "~components/BrandTile"



const Hits = ({ hits, refineNext, hasMore, heading, query }) => {

	if (query === '') {
		// randomize hits
		hits.sort(() => Math.random() - 0.5)
	}

	return (
	<>
		<SectionHeading>{heading}</SectionHeading>
    {hits.map(hit => <GridTile content={hit} key={hit.objectID} />)}
		{hasMore &&
			<LoadMore>
				<Line/>
				<MoreButton onClick={refineNext} borderButton>Load More</MoreButton>
				<Line/>
			</LoadMore>
		}
		{hits.length === 0 &&
			<NoMatches>No matches</NoMatches>
		}
  </>
	)
}

const CustomHits = connectInfiniteHits(Hits)

const SectionHeading = styled.h5`
	grid-column: span 12;
	margin-top: 50px;
	margin-bottom: -20px;
`

const NoMatches = styled.h6`
	grid-column: span 12;
	text-align: center;
	margin: 100px 0 50px;
`

const MoreButton = styled(Button)`
  margin: 0 22px;
	${tablet}{
    padding: 1px 5px;
	}
  ${mobile}{
    padding: 1px 10px;
    margin: 0 10px;
  }
`

const LoadMore = styled.div`
	/* margin-bottom: 50px; */
	margin-top: 50px;
	grid-column: span 12;
	display: grid;
  grid-template-columns: 1fr max-content 1fr;
  align-items: center;
`

const GridTile = styled(BrandTile)`
	grid-column: span 3;
	display: block;
	${mobile}{
		grid-column: span 6;
	}
`

const Line = styled.div`
  background: var(--black);
  height: 1px;
`

export default CustomHits