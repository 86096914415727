exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-z-js": () => import("./../../../src/pages/a-z.js" /* webpackChunkName: "component---src-pages-a-z-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-search-old-js": () => import("./../../../src/pages/search-old.js" /* webpackChunkName: "component---src-pages-search-old-js" */),
  "component---src-pages-stylesheet-js": () => import("./../../../src/pages/stylesheet.js" /* webpackChunkName: "component---src-pages-stylesheet-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/Article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-brand-js": () => import("./../../../src/templates/Brand.js" /* webpackChunkName: "component---src-templates-brand-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/Collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-column-js": () => import("./../../../src/templates/Column.js" /* webpackChunkName: "component---src-templates-column-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/Home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

