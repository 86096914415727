module.exports = {
  siteUrl: 'https://homeroom.com.au',
  previewURL: 'https://preview-homeroom.gtsb.io',
  previewWebhook: 'https://webhook.gatsbyjs.com/hooks/data_source/065f0498-6d76-4330-9fe5-2b1e8c17d8fc',
  netlifySiteName: 'homeroom-au',
  netlifyAppId: '6a36e4c8-5365-4506-8b77-04ac26c0c014',
  netlifyBuildHookId: '6135b6fc5389a9efe5cb8248',
	mailchimpUser: '6ab48e9f684401c624935d04e',
  mailchimpId: 'dfdd27fa1e',
  mailchimpUrl: 'https://homeroom.us6.list-manage.com/'
}