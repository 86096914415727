import React from 'react'
import styled from '@emotion/styled'
import { mobile } from '~styles/global'
import useSiteSettings from "~utils/useSiteSettings"
import Section from '~components/Section'
import RichText from '~components/RichText'
import {Link} from 'gatsby'
import SubscribeTicker from '~components/SubscribeTicker'
import SanityLink from '~components/SanityLink'

const Footer = ({ className }) => {

	const { headline, acknowledgement, socialLinks, pageLinks } = useSiteSettings()

  return(
    <footer className={className}>
			<SubscribeTicker />
			<Columns>
				<Headline className='h4'>
					<RichText content={headline}/>
				</Headline>
				<Links>
					<Heading className='caps'>Homeroom</Heading>
					<FooterLink to='/about'>About</FooterLink>
					<FooterALink href='mailto:hello@homeroom.com.au'>Contact</FooterALink>
					<FooterALink href='mailto:hello@homeroom.com.au'>Submit your label</FooterALink>
				</Links>
				<Social>
					<Heading className='caps'>Social</Heading>
					{socialLinks.map(socialLink=> (
						<SocialLink key={socialLink._key} href={socialLink.url} target='_blank'>
							{socialLink.linkText}
							</SocialLink>
					))}
					<SocialLink>
					</SocialLink>
				</Social>
				<Right>
					<Ack className='tiny'>
						<RichText content={acknowledgement}/>
					</Ack>
					<Credits className='tiny'>
						<Line>© homeroom {new Date().getFullYear()}</Line>
						{pageLinks.map((page, i) => (
							<><PageLink link={page.link}>{page.title}</PageLink><br/>
							</>
							
						))}
						<Line>
							<a href="https://celinetan.com/" target='_blank'>Design by Celine Tan</a>
						</Line>
						<Line>
							<a href="https://formwork.build/" target='_blank'>Build by Formwork</a>
						</Line>
					</Credits>
				</Right>
			</Columns>
    </footer>
  )
}

const Heading = styled.h6`
	font-weight: 600;
	margin-bottom: 10px;
	${mobile}{
		margin-bottom: 8px;
	}
`
const Columns = styled(Section)`
	background-color: var(--yellow);
	padding: 60px 0 54px;
	${mobile}{
		padding: 35px 0;
	}
`
const Headline = styled.div`
	grid-column: span 3;
	${mobile}{
		display: none;
	}
`
const Links = styled.div`
	grid-column: span 2;
	${mobile}{
		grid-column: 1/6;
		grid-row: 1;
	}
`
const FooterLink = styled(Link)`
	display: block;
`
const FooterALink = styled.a`
	display: block;
`
const Social = styled.div`
	grid-column: span 1;
	margin-left: -40px;
	${mobile}{
		grid-column: 1/6;
		grid-row: 2;
		margin-left: 0px;
	}
`
const SocialLink = styled.a`
	display: block;
`
const Right = styled.div`
	grid-column: span 6;
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-column-gap: 40px;
	${mobile}{
		grid-column: 7/13;
		grid-row: 1/3;
		grid-column-gap: 15px;
	}
`
const Ack = styled.div`
	grid-column: span 4;
	${mobile}{
		grid-column: span 6;
		margin-bottom: 16px;
	}
`
const Credits = styled.div`
	grid-column: span 2;
	${mobile}{
		grid-column: span 6;
	}
`
const Line = styled.div`
`
const Space = styled.span`
	display: ${props => props.hide ? 'none' : 'inline-block'};
	margin-right: 10px;
`
const PageLink = styled(SanityLink)`
	margin-right: 10px;
`

export default Footer
