import React from 'react'
import { css, keyframes } from "@emotion/react"
import styled from "@emotion/styled"
import { mobile } from "~styles/global"

const Ticker = ({ className, children, duration}) => {

  let num = Math.floor(30 / children.length)
  let content = children 

  return(
    <TickerWrapper className={className} css={css`> span {animation-duration: ${duration};}`}>
      <span>
          {[...Array(num)].map((e, i) => (
            <React.Fragment key={`tickerArray` + i}>
            {content}
            </React.Fragment>
          ))}
        </span>
        <span>
          {[...Array(num)].map((e, i) => (
            <React.Fragment key={`tickerArrayRepeat` + i}>
            {content}
            </React.Fragment>
          ))}
        </span>
    </TickerWrapper>
  )
}

const marquee = keyframes`
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    -webkit-transform: translate3d(-100%,0,0);
    transform: translate3d(-100%,0,0)
  }
`

const TickerWrapper = styled.div`
  overflow: hidden;
  display: flex;
	align-items: center;
	z-index: -1;
  white-space: nowrap;
  height: 40px;
  width: 100%;
  max-width: 100vw;
  > span{
    display: inline-block;
    animation: ${marquee} 200s infinite linear;
  }
  ${mobile}{
    height: 30px;
  }
`
export default Ticker