import React from 'react'
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import * as Flickity from 'react-flickity-component'
import "~styles/flickity.css";
import Section from './Section'
import { mobile } from "~styles/global"
import { Larr, Rarr, Heart } from './Svg';
import ProductTile from '~components/ProductTile';
import FeatureTitle from '~components/FeatureTitle'
import BrandTile from './BrandTile';

class SliderBlock extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isDragging: false,
    };
  }

  componentDidMount = () => {
    // You can register events in componentDidMount hook
      const component = this

      this.flkty.on('dragStart', function(){
        component.setState({
          isDragging: true
        })
      })

      this.flkty.on('dragEnd', function(){
        component.setState({
          isDragging: false
        })
      })
  }
  componentDidUpdate = () => {
    // Detect start and end of slides
    // if(!this.flkty.cells[ this.flkty.selectedIndex - 1 ]){
    //   console.log(`start`)
    // }
    // if(!this.flkty.cells[ this.flkty.selectedIndex + 1 ]){
    //   console.log(`end`)
    // }
  }

  render() {

    const myCustomPrev = () => {
      this.flkty.previous()
    }

    const myCustomNext = () => {
      this.flkty.next()
    }

    const flickityOptions = {
      prevNextButtons: false,
      pageDots: false,
      adaptiveHeight: false,
      wrapAround: false,
      cellAlign: 'center',
      freeScroll: true,
      contain: true,
      draggable: true,
    }

    
    return(
      <Section className={this.props.className} css={css`overflow: hidden;`}>
        {this.props.productSlider &&
          <MobileHeader> 
						{(this.props.title) &&
						<>
						{this.props.title && 
                <FeatureHeading>
                  <FeatureTitle title={this.props.title}/>
                  {this.props.heart && <CrushHeart />}
                </FeatureHeading>
              } 
						</>
						}
              <Arrows>
                <button onClick={() => myCustomPrev()} css={css`margin-right: 10px;`}>
                  <Larr css={css`height: 8px;`}/>
                </button>
                <button onClick={() => myCustomNext()}>
                  <Rarr css={css`height: 8px;`}/>
                </button>
              </Arrows>
          </MobileHeader>
        }
        {(this.props.brandsSlider || this.props.title) &&
          <Header css={css`${mobile}{display: ${(this.props.title ) ? `none` : `flex`}};}`}>
            {this.props.brandsSlider && 
              <h4>Brands mentioned in this article</h4>
            }
            {this.props.title && 
              <>
                <FeatureTitle title={this.props.title} />
                {this.props.heart && <CrushHeart />}
              </>
            }
          </Header>
        }
        <SliderButton 
          onClick={() => myCustomPrev()}
          css={css`justify-self: end; display: ${this.props?.slides?.length <= 4 ? `none` : `block`};`}
        >
          <Larr/>
        </SliderButton>
        <Flickity
          flickityRef={c => this.flkty = c}
          className={'carousel'} // default ''
          elementType={'div'} // default 'div'
          options={flickityOptions} // takes flickity options {}
          disableImagesLoaded={false} // default false
          // static // default false
          css={css`
            ${sliderCss}
            ${mobile}{
              grid-column: ${this.props.brandsSlider ? `1/13` : `1/13`};
            }
          `}
        >
          {this?.props?.slides?.map((slide, i) => (
            <React.Fragment key={slide._key ? slide._key : slide.title}>
							{this.props.brandsSlider ? 
								<BrandTile
									content={slide} 
									relatedBrand
									css={css`
										width: calc(20% - 32px); 
										margin-right: 40px;
										box-sizing: border-box;
										pointer-events: ${this.state.isDragging ? 'none' : 'all'};
										${mobile}{
											width: 35%; 
											margin-right: 15px;
										}
									`}
								/>
							:
								<ProductTile
									content={slide} 
									css={css`
										width: calc(25% - 30px); 
										margin-right: 40px;
										box-sizing: border-box;
										pointer-events: ${this.state.isDragging ? 'none' : 'all'};
										${mobile}{
											width: 79%; 
											margin-right: 15px;
										}
									`}
								/>
							}
            
            </React.Fragment>
          ))}
          {this.props.children}
        </Flickity>
        <SliderButton 
          onClick={() => myCustomNext()}
          css={css`justify-self: start; display: ${this.props?.slides?.length <= 4 ? `none` : `block`};`}
        >
          <Rarr/>
        </SliderButton>
      </Section>
    )
  }
}
const Header = styled.div`
  display: flex; 
  grid-column: span 12;
  justify-content: center;
  margin-bottom: 55px;
  text-align: center;
  ${mobile}{
    margin-bottom: 34px;
  }
`
const MobileHeader = styled.div`
  display: none;
  ${mobile}{
    display: grid; 
    grid-column: span 12;
    grid-template-columns: max-content 1fr;
    align-content: center;
    margin-bottom: 24px;
  }
`
const FeatureHeading = styled.div`
  display: flex;
  justify-content: center;
  justify-self:start;
`
const CrushHeart = styled(Heart)`
  width: 23px;
  height: 30px;
  margin-left: 12px;
`
const Arrows = styled.div`
  justify-self: end;
`
const SliderButton = styled.button`
 &>svg{
   height: 10px
 }
 ${mobile}{
   display: none;
 }
`
const sliderCss = `
  .flickity-slider{
    margin-right: -40px;
  }
  .carousel-cell { margin-right: 10px; }
  grid-column: 2/12;
  ${mobile}{
    .flickity-viewport {
      overflow: visible;
    }
  }
`

const CenteredSlider = (props) => {
  return <SliderBlock {...props} />
} 

export default CenteredSlider
