import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import resolveLink from '~utils/resolveLink'
import { Link } from 'gatsby'
import Image from '~components/Image'
import SanityLink from '~components/SanityLink'

const MenuTagTile = ({ className, content, circle, aspectRatio }) => {
	return (
		<Wrap className={className} link={content.link} flex={circle}>
			<Thumbnail circle={circle} image={content.image} aspectRatio={aspectRatio}/>
			<Title inline={circle} className='small'>{content.title}</Title>
		</Wrap>
	)
}

const Wrap = styled(SanityLink)`
	display: ${props => props.flex ? 'flex' : 'block'};
	align-items: center;
`
const Thumbnail = styled(Image)`
	border-radius: ${props => props.circle ? '50px' : '0'};
	margin-right: ${props => props.circle ? '13px' : '0'};
	min-width: 35px;
	margin-bottom: ${props => props.circle ? '0' : '20px'};
	overflow: hidden;
  ${mobile}{
		margin-bottom: ${props => props.circle ? '0' : '8px'};
  }
`
const Title = styled.h6`
	
`
MenuTagTile.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
	circle: PropTypes.bool,
	aspectRatio: PropTypes.number,
}

export default MenuTagTile