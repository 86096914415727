import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { Link } from 'gatsby'
import Image from '~components/Image'
import { Star } from '~components/Svg'
import resolveLink from '~utils/resolveLink'

const BrandTile = ({ className, content, favourite, relatedBrand }) => {
	if (relatedBrand) {
		return(
      <Link to={resolveLink(content)} className={className}>
        <ImageContainer>
           <Image 
             image={content?.content?.thumb}
             aspectRatio={0.713}
           />
        </ImageContainer>
        <h6 className="small" css={css``}>{content?.title}</h6>
        <span className="caps" css={css`${mobile}{display: none;}`}>Read More</span>
      </Link>
    )
	} else {
		return (
			<Link to={resolveLink(content)} className={className}>
				{content?.content?.thumb ? (
				<ImageContainer>
						<Image 
							image={content?.content?.thumb}   
							aspectRatio={0.713}
						/>
				</ImageContainer>
				) : (
					<ImageContainer>
						<img style={{width: '100%', display: 'block'}} src="https://www.colorbook.io/imagecreator.php?hex=F5F5F5&width=713&height=1000" />
					</ImageContainer>
				)}
				<div css={css`display:flex; align-items: center;`}>
				<h6 css={css`${mobile}{font-size: 16px; line-height: 22px;}`}>{content.title}</h6>
					{favourite &&
						<Star css={css`
							margin-left: 15px;
							width: 20px;
							height: 20px;
						`}/>
					}
				</div>
			</Link>
		)
	}
	
}

const Wrap = styled.div`
	
`
const ImageContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
	overflow: hidden;
  ${mobile}{
    margin-bottom: 8px;
  }
`

BrandTile.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
	favourite: PropTypes.bool,
	relatedBrand: PropTypes.bool
}

export default BrandTile