import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'
import ProductTile from '~components/ProductTile'
import Figure from '~components/Figure'

const HeroAndProducts = ({ className, reverse, title, products, hero }) => {

	return (
		<Wrap className={className}>
			<TitleMobile>{title}</TitleMobile>
			<Hero reverse={reverse}>
				{	hero?.type === 'image' ? 
					<MainFigure content={hero.image}/>
					:
					<HeroProduct content={hero.product}/>
				}
			</Hero>
			<ProductsCol reverse={reverse}>
				<Title>{title}</Title>
				<Products>
				{products?.map(product => (
					<Product key={product._key} content={product}/>
				))}
				</Products>
			</ProductsCol>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	margin: 90px 0;
	${mobile}{
		margin: 30px 0;
	}
`
const Hero = styled.div`
	grid-column: ${props => props.reverse ? '7/12' : '2/7'};
	grid-row: 1;
	${mobile}{
		grid-column: span 12;
		grid-row: 2;
		margin-bottom: 14px;
	}
`
const ProductsCol = styled.div`
	grid-column: ${props => props.reverse ? '2/6' : '8/12'};
	grid-row: 1;
	margin: 0 34px;
	${mobile}{
		grid-row: 3;
		margin: 0;
		grid-column: span 12;
	}
`
const Products = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 -20px;
	${mobile}{
		margin: 0 -7px;
		grid-column: span 12;
	}
`
const Title = styled.h3`
	text-align: center;
	margin-bottom: 55px;
	${mobile}{
		display: none;
	}
`
const TitleMobile = styled.h3`
	text-align: center;
	display: none;
	${mobile}{
		display: block;
		grid-column: span 12;
		grid-row: 1;
		margin-bottom: 25px;
	}
`
const HeroProduct = styled(ProductTile)`
`
const Product = styled(ProductTile)`
	flex: 50%;
	max-width: 50%;
	padding: 0 20px;
	margin-bottom: 20px;
	box-sizing: border-box;
	${mobile}{
		margin-bottom: 20px;
		padding: 0 7px;
	}
`
const MainFigure = styled(Figure)`
	height: auto;
	margin-top: 0;
  margin-bottom: 0;
`

HeroAndProducts.propTypes = {
	className: PropTypes.string
}

export default HeroAndProducts