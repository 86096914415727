import React from "react"

import { SiteStore } from "~context/siteContext"
import refreshPreview from "~utils/refreshPreview"

const isDev = process.env.NODE_ENV === 'development'

export const wrapRootElement = ({ element }) => (
  <SiteStore>{element}</SiteStore>
)

const transitionDelay = 700
export const shouldUpdateScroll = ({
  routerProps: { location },
	prevRouterProps,
  getSavedScrollPosition,
}) => {
	if(
		(prevRouterProps?.location?.pathname.includes('search') && location.pathname.includes('/search')) ||
		(prevRouterProps?.location?.pathname.includes('a-z') && location.pathname.includes('/a-z')) ||
		((prevRouterProps?.location?.search.includes('brandsPage') || prevRouterProps?.location?.search.includes('articlesPage')) && (location?.search.includes('brandsPage') || location?.search.includes('articlesPage')))
		) return false
		console.log(prevRouterProps?.location)
  window.history.scrollRestoration = "manual"
  const currentPosition = getSavedScrollPosition(location)
  window.setTimeout(() => {
    window.scrollTo(...currentPosition)
  }, transitionDelay )
  return false
}


export const onClientEntry = () => {
  // refresh the preview on hard reload
  if(isDev){
    refreshPreview()
  }
}
