import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ChunkyArrow, Close } from '~components/Svg'
import Section from '~components/Section'
import { mobile, tablet } from '~styles/global'
import useSiteSettings from '~utils/useSiteSettings'
import { AnimatePresence, motion } from 'framer-motion'
import {subscribe} from 'klaviyo-subscribe'

const KlaviyoForm = ({ className, closeForm }) => {
  const [values, setValues] = useState({})
  const [errors, setErrors] = useState([])
  const [success, setSuccess] = useState(false)
	const { subHeading, heading, placeholder, successPreMessage, successMessage } = useSiteSettings()

	const listId = 'WGdVZD'

  const validate = e => {
    e.preventDefault()
    const newErrors = []

    if(!values.email) newErrors.push({
      field: 'email',
      message: 'The email field is required'
    })

    if(values.email && (!values.email.includes('@') || !values.email.includes('.'))) newErrors.push({
      field: 'email',
      message: 'Please check your email address'
    })

    if(newErrors.length === 0){
      submit(e)
    }
    else{
      setErrors(newErrors)
    }
  }

  const submit = e => {
    const form = e.currentTarget
		subscribe(listId, values.email, {
			$first_name: values.fname, 
			$last_name: values.lname
		})
			.then(res => {
				form.reset()
				setSuccess(true)
			})

  }


  const errorFields = errors.map(error => error.field)

  return (
    <Wrap className={className}>
			<CloseButton className='caps' onClick={() => closeForm()}>
				<Close />
			</CloseButton>
      <FormWrap>
				<AnimatePresence exitBeforeEnter>
					<Text 
						key={success}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.6 }}
					>
						<PreHeading className='caps'>{success ? successPreMessage : subHeading}</PreHeading>
						<Heading>{success ? successMessage : heading}</Heading>
					</Text>
				</AnimatePresence>
        <Form onSubmit={e => validate(e)} noValidate success={success}>
          <Input
					autoFocus
          type="email"
          name="email"
					placeholder={placeholder}
          css={css`
            border-color: ${errorFields.includes('email') ? 'var(--red)' : 'black'} ;
          `}
          onChange={e => setValues({...values, email: e.target.value})}
          />
          <button type="submit">
            <SubmitArrow />
          </button>
        </Form>
      </FormWrap>
    </Wrap>
  )
}

const Wrap = styled.div`
  position: relative;
	background: var(--pink);
	padding: 1.5rem 0;
	border-top: 1px solid var(--black);
	${mobile}{
		padding: 2.5rem 0 2rem;
	}
`

const CloseButton = styled.button`
	position: absolute;
	top: 1.8rem;
  right: 2.6rem;
	display: flex;
	z-index: 2;
	${mobile}{
		top: 1rem;
		right: 1rem;
	}
	span{
		display: block;
		margin-right: 1em;
		transform: translateY(-1px);
		font-weight: 600;
		${mobile}{
			transform: none;
		}
	}
	svg{
		width: 16px;
		${mobile}{
			width: 14px;
		}
	}
`

const Message = styled.span`
	display: inline-block;
	margin-right: 0.6em;
	flex-shrink: 0;
	${tablet}{
		display: block;
	}
`

const FormWrap = styled(Section)`
  position: relative;
  transition: opacity 0.3s;
	text-align: left;
	form {
	}
`
const Text = styled(motion.div)`
	grid-column: span 6;
	${tablet}{
		grid-column: span 12;
	}
`
const Heading = styled.div`
	text-transform: uppercase;
	font-family: 'Schnyder', serif;
	line-height: 190%;
	letter-spacing: 0.1em;
`
const PreHeading = styled.div`
	font-weight: 600;
	${tablet}{
		margin-bottom: 4px;
	}
`
const Form = styled.form`
	grid-column: span 6;
	/* position: relative;
	top: 12px; */
	opacity: ${props => props.success ? '0' : '1'};
  pointer-events: ${props => props.success ? 'none' : 'all'};
	display: flex;
	margin-right: 58px;
	${tablet}{
		grid-column: span 12;
		margin-right: 0px;
	}
`
const Input = styled.input`
	border-bottom: none;
	background-image: linear-gradient(to right, black 20%, rgba(255,255,255,0) 0%);
  background-position: bottom;
	background-size: 5px 1px;
	background-repeat: repeat-x;
	padding-bottom: 0;
	max-width: 435px;
	width: 100%;
	height: 32px;
	background-color: transparent;
	border-color: black;
	position: relative;
	top: 12px;
	::placeholder{
		color: inherit;
		opacity: opacity: 0.75;
	}
	&:focus{
		outline: none;
	}
	${tablet}{
		top: 0;
		margin-top: 10px;
	}
`
const SubmitArrow = styled(ChunkyArrow)`
	width: 25px;
	display: block;
	transform: translateY(4px);
	margin-left: 14px;
`

const Errors = styled.div`
  margin-top: -10px;
  margin-bottom: 20px;
`

const Success = styled.h5`
  position: absolute;
  transition: opacity 0.3s 0.3s;
`

KlaviyoForm.propTypes = {
  showName: PropTypes.bool,
	closeForm: PropTypes.func,
}

export default KlaviyoForm
