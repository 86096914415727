import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import ProductTile from '~components/ProductTile'
import Section from '~components/Section'

const ProductGrid = ({ className, products, title, productsPerRow }) => {
	return (
		<Wrap className={className}>
				{title &&
          <Header>
						<Title className='h4'>{title}</Title>
          </Header>
				}
			<Row>
			{products?.map((product, index) => (
				<Product
					w={100 / productsPerRow + '%'}
					content={product} 
					css={css`
						padding: 0 20px;
						box-sizing: border-box;
						margin-bottom: 20px;
						${mobile}{
							padding: 0 7px;
							margin-bottom: 20px;
						}
					`}
				/>
			))}
			</Row>
		</Wrap>
	)
}

const Wrap = styled(Section)`
`
const Header = styled.div`
	grid-column: span 12;
	text-align: center;
`
const Title = styled.div`
	margin-bottom: 55px;
	${mobile}{
		margin-bottom: 25px;
	}
`
const Row = styled.div`
	grid-column: 2/12;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 -20px;
	${mobile}{
		margin: 0 -7px;
		grid-column: span 12;
	}
`
const Product = styled(ProductTile)`
	flex: ${props => props.w};
	max-width: ${props => props.w};
	${mobile}{
		flex: 50%;
		max-width: 50%;
	}
`

ProductGrid.propTypes = {
	className: PropTypes.string
}

export default ProductGrid