import React, { useEffect } from 'react'
import { css, Global } from '@emotion/react'
import GlobalStyles from "~styles/global"
import Header from '~components/Header'
import Footer from '~components/Footer'
import { AnimatePresence, motion } from 'framer-motion'
import { useLocation } from "@reach/router"
import smoothscroll from 'smoothscroll-polyfill'
import { mobile } from "~styles/global"
import "~styles/static.css"
import styled from '@emotion/styled'
import { useSiteState } from '~context/siteContext'
import PopUp from '~components/PopUp'
import CookiesPopUp from '~components/CookiesPopUp'
import { Helmet } from 'react-helmet'
import { hotjar } from 'react-hotjar'

const Layout = ({ children }) => {
  const location = useLocation()
	const [siteState, setSiteState] = useSiteState()

	const closeMenu = () => {
		setSiteState(prevState => ({
			...prevState,
			menuOpen: false,
		}))
	}

  useEffect(() => {
    smoothscroll.polyfill()
  }, [])

	useEffect(() => {
		hotjar.initialize(3648546, 6)
	}, [])

	
  return (
    <>
      <GlobalStyles />
      <Header />
			<Global styles={css`
				body {
					overflow: ${siteState.menuOpen ? 'hidden' : 'auto'};
				}
			`}/>
      <main>
        <AnimatePresence exitBeforeEnter>
          <div key={location.pathname}>
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.6 }}
            >
              <div css={css`
                padding-top: 50px;
              `}>
                {children}
                <Footer css={css`margin-top: auto;`}/>
								<Overlay 
									onClick={()=> closeMenu()}
									css={css`
										pointer-events: ${siteState.menuOpen ? 'all' : 'none'};
								`}/>
								<PopUp/>
								<CookiesPopUp/>
              </div>
            </motion.div>
          </div>
        </AnimatePresence>
      </main>
			<Helmet>
				<meta name="p:domain_verify" content="a68badd400289395a9722f507a4b2119"/>
			</Helmet>
			{location?.hostname?.includes('gatsbyjs') &&
				<Helmet>
					<script id="show-banner" strategy="afterInteractive">
					{`
								window.markerConfig = {
									destination: '63fbebf50500c17e13d4aa93', 
									source: 'snippet'
								};

								!function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);
							`}
					</script>
				</Helmet>
			}
			
    </>
  )
}

const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
`

export default Layout
