import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css, Global } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { navigate } from 'gatsby'
import Section from '~components/Section'
import algoliasearch from "algoliasearch/lite"
import { InstantSearch, connectAutoComplete } from "react-instantsearch-dom"
import SearchBox from '~components/SearchBox'
import SearchResults from '~components/SearchResults'
import Button from '~components/Button'
import { useSiteState } from '~context/siteContext'

const Autocomplete = ({ hits, currentRefinement, refine, className, visible = null, active }) => {

	const handleClick = hitQuery => {
		let link = '/search?'
		if(active && active !== 'both'){
			link += `e=${active}&`
		}
		if(hitQuery){
			link += `q=${hitQuery}`
		}
		navigate(link)
		setSiteState(prevState => ({...prevState, searchOpen: false}))
	}

	const [siteState, setSiteState] = useSiteState()

	return (
		visible && (
			<div className={className}>
				{hits?.map(hit => (
						currentRefinement !== hit.query &&
						<>
							<Suggestion onClick={() => handleClick(hit.query)} key={hit.objectID}>{hit.query}</Suggestion><br/>
						</>
				))}
			</div>
		)
	)
}

const Suggestion = styled.button`
	${tablet}{
		padding: 0.4em 0;
	}
`

const CustomAutocomplete = connectAutoComplete(Autocomplete)

const SearchOverlay = ({ className }) => {

	const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )

	function onSearchStateChange(updatedSearchState) {
		setQuery(updatedSearchState.query)
	}	

	const [active, setActive] = useState('both')
	const [query, setQuery] = useState()

	const [url, setUrl] = useState('/search')

	useEffect(() => {
		let link = '/search?'
		if(active && active !== 'both'){
			link += `e=${active}&`
		}
		if(query){
			link += `q=${query}`
		}
		setUrl(link)
	}, [query, active])

	const handleSubmit = e => {
		e.preventDefault()
		setSiteState(prevState => ({...prevState, searchOpen: false}))
		navigate(url)
	}

	const [siteState, setSiteState] = useSiteState()

	return (
		<>
			<Global styles={css`
				body, html{
					overflow: hidden;
				}
			`}/>
			<Wrap className={className}>
				<Filters>
					<FilterButton 
						borderButton 
						active={active === 'brands'}
						onClick={()=> setActive('brands')}
					>Directory</FilterButton>
					<FilterButton 
						borderButton 
						active={active === 'articles'}
						onClick={()=> setActive('articles')}
					>Journal</FilterButton>
					<FilterButton 
						borderButton 
						active={active === 'both'}
						onClick={()=> setActive('both')}
					>Both</FilterButton>
				</Filters>
				<Block>
				<InstantSearch
						searchClient={searchClient}
						// routing={true}
						indexName="Suggestions"
						onSearchStateChange={onSearchStateChange}
					>
					<SearchBox isHeader onSubmit={handleSubmit}/>
					<StyledAutocomplete visible={query} active={active} />
				</InstantSearch>
				</Block>
			</Wrap>
			<CloseBackground onClick={() => setSiteState(prevState => ({...prevState, searchOpen: false}))}/>
		</>
	)
}

const Wrap = styled(Section)`
	padding-top: 35px;
	padding-bottom: 80px;
	/* box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.1); */
	border-bottom: 1px solid #000;
	${tablet}{
		padding-top: 25px;
		padding-bottom: 30px;
	}
`
const Block = styled.div`
	grid-column: span 12;
`
const Filters = styled(Block)`
	margin-bottom: 50px;
	${tablet}{
		margin-bottom: 35px;
	}
`
const FilterButton = styled(Button)`
	margin-right: 20px;
	${tablet}{
		margin-right: 15px;
	}
`
const StyledSearchBox = styled(SearchBox)`
	grid-column: span 9;
`

const StyledAutocomplete = styled(CustomAutocomplete)`
	margin-top: 30px;
	grid-column: 1 / 8;
	${tablet}{
		margin-top: 20px;
	}
`

const CloseBackground = styled.button`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9;
	cursor: default;
`

SearchOverlay.propTypes = {
	className: PropTypes.string
}

export default SearchOverlay