import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { Link } from 'gatsby'
import Image from '~components/Image'
import resolveLink from '~utils/resolveLink'
import SanityLink from '~components/SanityLink'

const MenuTile = ({ className, content, link }) => {
	return (
		<SanityLink link={content?.button?.link ?? link} className={className}>
			{content?.content?.thumb || content.image ? (
			<ImageContainer>
					<Image 
						image={content?.content?.thumb || content?.image}   
						aspectRatio={0.713}
					/>
			</ImageContainer>
			) : (
				<ImageContainer>
					<img style={{width: '100%', display: 'block'}} src="https://www.colorbook.io/imagecreator.php?hex=F5F5F5&width=713&height=1000" />
				</ImageContainer>
			)}
			<div css={css`display:flex; align-items: center;`}>
				<h6 className="small">{content?.button?.text ?? content?.title}</h6>
			</div>
		</SanityLink>
	)
}

const Wrap = styled.div`
	
`
const ImageContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
	overflow: hidden;
  ${mobile}{
    margin-bottom: 8px;
  }
`

MenuTile.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}
export default MenuTile