import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from './Image'
import { Link } from 'gatsby'
import resolveLink from '../utils/resolveLink'

const ConditionalWrapper = ({ condition, linkWrapper, divWrapper, children }) => 
  condition ?  linkWrapper(children) : divWrapper(children);

const ProductTile = ({ className, content }) => {
	return(
		<ConditionalWrapper
			condition={content.link}
			linkWrapper={children => <a href={content.link} target="_blank" className={className}>{children}</a>} 
			divWrapper={children => <div className={className}>{children}</div>}
		> 
			<div css={css`margin-bottom: 19px;${mobile}{margin-bottom: 13px;}`}>
				<Image image={content?.image} />
			</div>
			<div css={css``}>
				<span className="caps" css={css`display: block; margin-bottom: 6px;${mobile}{margin-bottom: 4px;}`}>{content.brand}</span>
				<span className="small" css={css`display: block;`}>{content.title}</span>
				<span className="caps">${content.price}</span>
			</div>
		</ConditionalWrapper>
	)
}

const Wrap = styled.div`
	
`

ProductTile.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default ProductTile