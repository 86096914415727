import React from 'react'
import { css } from "@emotion/react"
import { tablet } from "~styles/global"
import { Link } from 'gatsby'
import resolveLink from '~utils/resolveLink'

const Button = ({ className, children, to, borderButton, onClick, active, yellowHover }) => {
  if(to?.type === `externalLink`){
    return(
			<a 
				href={to.link} 
				className={`caps ${className}`} 
				css={css`
					${buttonCss}
					${borderButton && borderButtonCss} 
				`} 
				target="_blank"
			>
        {children}
      </a>
    )
  } else if (to){
    return(
			<Link 
				className={`caps ${className}`}
				css={css`
					${buttonCss}
					${borderButton && borderButtonCss}
					${yellowHover && `&:hover{
						background: var(--yellow)};
						color: var(--black) !important;
						`}
				`} 
				to={resolveLink(to)}
			>
        {children}
      </Link>
    )
  } else {
    return(
			<button 
				className={`caps ${className}`}
				css={css`
					${buttonCss}
					${borderButton && borderButtonCss} 
					${active && activeCss}
				`
				} 
				onClick={onClick}
			>
        {children}
      </button>
    )
  }
}

const buttonCss = `
  display: inline-block;
`
const borderButtonCss = `
  border: 1px solid var(--black);
  padding: 5px 12px 7px;
	transition: color 0.3s, background-color 0.3s;
	background: var(--white);
	&:hover{
		background-color: var(--black);
		color: var(--white);
	}
  ${tablet}{
    padding: 3px 7px 4px;
  }
`
const activeCss = `
	background-color: var(--black);
	color: var(--white);
`
export default Button
