import React, { useState, useContext } from 'react'

const initialSiteState = {
  pageTitle: null,
	menuOpen: false,
	menuView: undefined,
	searchOpen: false,
	popUp: false
}

export const SiteContext = React.createContext({
  siteState: initialSiteState,
  setSiteState: undefined,
})

export const SiteStore = ({ children }) => {
  const [siteState, setSiteState] = useState(initialSiteState)

  return(
    <SiteContext.Provider value={{
      siteState: siteState,
      setSiteState: setSiteState
    }}>
      {children}
    </SiteContext.Provider>
  )
}

// hook to access siteState globally
export const useSiteState = () => {
  const { siteState, setSiteState } = useContext(SiteContext)
  return [siteState, setSiteState]
}
