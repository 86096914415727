import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { Link } from 'gatsby'
import Image from '~components/Image'
import resolveLink from '~utils/resolveLink'

const ArticleTile = ({ className, content, aspectRatio, menu }) => {
	const [hovered, setHovered] = useState(false)

	return(
		<Link 
			to={resolveLink(content)} 
			className={className} 					
			onMouseEnter={() => setHovered(true)} 
			onMouseLeave={() => setHovered(false)}
		>
			<ImageContainer >
				 <Label className="caps">{content.content?.column?.title}</Label>
				 <FlexImage
					 image={content?.content?.thumb}
					 aspectRatio={aspectRatio ?? 0.713}
					 css={css`transform: ${hovered ? `scale(1.02)` : `scale(1)`};`}
				 />
			</ImageContainer>
			{menu ? 
			<h6 className='small'>{content.title}</h6>
			:
			<h6 css={css`${mobile}{font-size: 16px; line-height: 22px;}`}>{content.title}</h6>
			}
		</Link>
	)
}

const Wrap = styled.div`
`
const ImageContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
	overflow: hidden;
  ${mobile}{
    margin-bottom: 8px;
  }
`
const FlexImage = styled(Image)`
	transition: transform 0.3s;
`
const Label = styled.div`
  position: absolute;
  top: 10px;
  left: 13px;
  padding: 7px 8px;
  z-index: 2;
  background-color: var(--white);
  ${mobile}{
    top: 7px;
    left: 10px;
    padding: 4px 6px;
    font-size: 9px;
    line-height: 12px;
  }
`

ArticleTile.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
	aspectRatio: PropTypes.string,
}

export default ArticleTile