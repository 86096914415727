const resolveLink = (doc) => {
  if(!doc) return null
  const type = doc._type
  const slug = doc.slug?.current
  switch (type) {
		case 'homePage':
      return `/`
		case 'tag':
			return `/search?q=${doc.title}`
    case 'page':
      return `/${slug}`
    case 'brand':
      return `/directory/${slug}`
		case 'featuredBrand':
			return `/directory/${slug}`
    case 'article':
      return `/journal/${slug}`
    case 'featuredArticle':
      return `/journal/${slug}`
    case 'column':
      return `/journal/${slug}`
		case 'az':
			return `/brands/${slug}`
    default:
      return `/${slug}`
  }

}

export default resolveLink
