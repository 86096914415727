import React from "react"
import { Global, css } from "@emotion/react"

export const breakpoints =  {
  mobile: '@media (max-width: 768px)',
  tablet: '@media (max-width: 1024px)',
  desktop: '@media (max-width: 1230px)',
  desktopUp: '@media (min-width: 1025px)'
}

export const mobile = breakpoints.mobile
export const tablet = breakpoints.tablet
export const desktop = breakpoints.desktop
export const desktopUp = breakpoints.desktopUp


const GlobalStyles = () => (
  <>
  <Global styles={reset} />
  <Global styles={styles} />
  </>
)

const styles = css`

  body {
    font-family: 'Times Now', serif;
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
    overscroll-behavior-x: none;
    ${tablet}{
      font-size: 18px;
      line-height: 22px;
    }
  }

  :root{
    --white: #ffffff;
    --black: #000000;
    --yellow: #DBFB6E;
    --off-white: #f7f7f5;
    --red: #EB533D;
		--pink: #F3D6F5;
		--yellow-light: #EAFFA1;
		--highlighter-pink: #F3D6F5;
		--highlighter-pink-light: #F9E8F8;
  }

  h1,
  .h1 {
    font-family: 'Schnyder', serif;
    font-size: 80px;
    line-height: 80px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-weight: 300;
    ${tablet} {
      font-size: 40px;
      line-height: 1.125;
    }
  }

  h2,
  .h2 {
    font-family: 'Schnyder', serif;
    font-size: 60px;
    line-height: 68px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-weight: 300;
    ${tablet} {
      font-size: 30px;
      line-height: 38px;
    }
  }

  h3,
  .h3 {
    font-family: 'Times Now', serif;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.01em;
    font-weight: 300;
    ${tablet} {
      font-size: 26px;
      line-height: 30px;
    }
  }

  h4,
  .h4 {
    font-family: 'Schnyder', serif;
    font-size: 30px;
    line-height: 38px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 300;
    ${tablet} {
      font-size: 22px;
      line-height: 28px;
    }
  }

  h5,
  .h5 {
    font-family: 'Satoshi', sans-serif;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.01em;
    font-weight: 500;
    ${tablet} {
      font-size: 20px;
      line-height: 26px;
    }
  }

  h6,
  .h6 {
    font-family: 'Times Now', serif;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: -0.01em;
    font-weight: 300;
    ${tablet} {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .h7 {
    font-family: Georgia, serif;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.01em;
    font-style: italic;
    font-weight: 400;
    ${tablet} {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .h8 {
    font-family: 'Schnyder', serif;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 300;
    ${tablet} {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .caps {
    font-family: 'Satoshi', sans-serif;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.08em;
    font-weight: 500;
    text-transform: uppercase;
    font-style: normal;
    ${tablet} {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .highlight {
    font-family: Georgia, serif;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.03em;
    font-style: italic;
    font-weight: 400;
    ${tablet} {
      /* Not defined */
    }
  }

  .highlight-l {
    font-family: Georgia, serif;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.01em;
    font-style: italic;
    font-weight: 400;
    ${tablet} {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .highlight-s {
    font-family: Georgia, serif;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    font-style: italic;
    font-weight: 400;
		p>a {
			font-size: inherit !important;
		}
    ${tablet} {
      /* Not defined */
    }
  }

	p > a{
		border-bottom: 1px solid;
	}

  .module-styles p > a, .highlight-inline {
    font-family: Georgia, serif;
    font-size: 16px;
    letter-spacing: 0.03em;
    font-style: italic;
    font-weight: 400;
    line-height: 30px; 
    position: relative; 
		text-decoration: none;
    ${tablet} {
      font-size: 14px;
      line-height: 22px;
    }
  }

  .small{
    font-family: 'Times Now', serif;
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    ${tablet}{
      font-size: 14px;
      line-height: 20px;
    }
  }

  .tiny {
    font-family: 'Satoshi', sans-serif;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.02em;
    font-weight: 500;
    ${tablet} {
			font-size: 12px;
      line-height: 18px;
    }
  }

  p{
    margin-bottom: 0.5em;
    &:last-child{
      margin-bottom: 0;
    }
  }

	input {
		font-size: inherit;
		font-family: inherit;
		letter-spacing: inherit;
		text-transform: inherit;
		border: none;
		-webkit-appearance: none;
		border-bottom: 1px solid var(--black);
	}

	* {
		&:focus{
			outline-color: var(--yellow);
			outline-offset: 5px;
		}
	}


`
const reset = css`

  body {
    margin: 0px;
    min-height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
    font-size: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  ul, ol, blockquote {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0.5em 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  }

  button{
    -webkit-appearance: none;
    border: none;
    background: none;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
    padding: 0;
    margin: 0;
    text-transform: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
  }

  input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border: none;
		-webkit-text-fill-color: inherit;
		-webkit-box-shadow: 0 0 0px 0px transparent inset;
		transition: background-color 5000000s ease-in-out 0s;
	}

	input, select {
		border-radius: 0;
		-webkit-appearance: none;
	}

	/* Prevent safari blue links */
	button, option {
    color: inherit;
  }

	input {
		padding: 0;
	}
`

export default GlobalStyles
