import { graphql, useStaticQuery } from "gatsby"

const useSiteSettings = () => {

  const siteSettings = useStaticQuery(graphql`
    query {
      sanitySiteSettings(_id: {regex: "/(drafts\\\\.)?siteSettings/"}) {
        socialImage {
          asset {
            _id
          }
        }
        metaDescription
        siteTitle
				subscribeMessage
				headline: _rawHeadline(resolveReferences: {maxDepth: 5}),
				acknowledgement: _rawAcknowledgement(resolveReferences: {maxDepth: 5}),
				socialLinks {
					linkText
					url
					_type
					_key
				}
				pageLinks{
					title
					link{
						...link
					}
				}
				preQuestion
				question
				inputPlaceholder
				buttonText
				thankyouMessage
				subHeading
				heading
				placeholder
				successPreMessage
				successMessage
				emailDestination
				cookiesMessage
				cookiesReadMoreLink{
					linkText
					to {
						slug {
							current
						}
						_type
					}
				}
				acceptText
      }
    }
    `)

  const settings = siteSettings.sanitySiteSettings

  return {
    siteTitle: settings.siteTitle,
    siteMetaDescription: settings.metaDescription,
    siteSocialImage: settings.socialImage,
		subscribeMessage: settings.subscribeMessage,
		headline: settings.headline,
		acknowledgement: settings.acknowledgement,
		socialLinks: settings.socialLinks,
		pageLinks: settings.pageLinks,
		preQuestion: settings.preQuestion,
		question: settings.question,
		inputPlaceholder: settings.inputPlaceholder,
		buttonText: settings.buttonText,
		thankyouMessage: settings.thankyouMessage,
		subHeading: settings.subHeading,
		heading: settings.heading,
		placeholder: settings.placeholder,
		successPreMessage: settings.successPreMessage,
		successMessage: settings.successMessage,
		emailDestination: settings.emailDestination,
		cookiesMessage: settings.cookiesMessage,
		cookiesReadMoreLink: settings.cookiesReadMoreLink,
		acceptText: settings.acceptText
  }
}

export default useSiteSettings
