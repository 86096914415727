import React from "react"
import PropTypes from 'prop-types'
import styled from "@emotion/styled"
import { mobile } from "~styles/global"

const FeatureTitle = ({ className, title }) => {

  const fullTitle = title.split(" / ")
  const action = fullTitle[0]
  const titleLarge = fullTitle[1]

  return (
    <div className={className}>
      <Action className="caps">{action}</Action>
      <Title><span>/</span>{titleLarge}</Title>
    </div>
  )
}

export default FeatureTitle


FeatureTitle.propTypes = {
  action: PropTypes.string,
  title: PropTypes.string,
}

const Action = styled.span`
  vertical-align: top;
  display: inline-block;
  margin-right: 7px;
  font-weight: 600;
`
const Title = styled.h3`
  line-height: 1;
  display: inline-block;
  > span{
    display: inline-block;
    margin-right: 8px
  }
`