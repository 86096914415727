import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import useSiteSettings from '~utils/useSiteSettings'
import { CrossLarge } from '~components/Svg'
import ContactForm from '~components/ContactForm'
import { AnimatePresence, motion } from 'framer-motion'
import { useSiteState } from '~context/siteContext'
import Cookies from 'js-cookie'

const PopUp = ({ className }) => {
	const { preQuestion, question, inputPlaceholder, buttonText, thankyouMessage, emailDestination} = useSiteSettings()

	const [siteState, setSiteState] = useSiteState()

	const [success, setSuccess] = useState(false)

	useEffect(() => {
		if(!Cookies.get('popUpClosed') && Cookies.get('cookiesPopUpClosed')){
			// setTimeout(() => {
			// 	setSiteState(prevState => ({
			// 		...prevState,
			// 		popUp: true
			// 	}))
			// }, 3000)
    }
	}, [siteState.cookiesPopUp])

	const close = () => {
		Cookies.set('popUpClosed', true, { expires: 7 })
		setSiteState(prevState => ({
			...prevState,
			popUp: false
		}))
	}

	// useEffect(() => {
	// 	console.log('popUpClosed', Cookies.get('popUpClosed'))
	// }, [])
	return (
		<Wrap className={className} show={siteState.popUp }>
			<Close onClick={() => close()}>
				<Cross />
			</Close>
			<AnimatePresence exitBeforeEnter>
			<Top  
				key={success}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.6 }}
			>
				<Pre className='caps'>
					{success ? 'Thank You!' : preQuestion}
				</Pre>
				<Question className='h6'>
					{success ? thankyouMessage : question}
				</Question>
			</Top>
			</AnimatePresence>
			<Bottom success={success}>
				<Form placeholder={inputPlaceholder} buttonText={buttonText} onSuccess={() => setSuccess(true)} emailDestination={emailDestination}/>	
			</Bottom>	
		</Wrap>
	)
}

const Wrap = styled.div`
	position: fixed;
	bottom: 50px;
	right: 42px;
	width: 425px;
	border: 1px solid var(--black);
	min-height: 214px;
	z-index: 2;
	background-color: var(--highlighter-pink);
	transform: ${props => props.show ? 'translateY(0)' : 'translateY(100%)'};
	opacity: ${props => props.show ? '1' : '0'};
	transition: opacity 0.3s, transform 0.3s;
	pointer-events:  ${props => props.show ? 'all' : 'none'};
	box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.2);
	${mobile}{
		min-height: 194px;
		width: 100%;
		max-width: calc(100% - 32px);
		bottom: 20px;
		left: 15px;
		right: 15px;
	}
`
const Top = styled(motion.div)`
	padding: 20px 20px 12px;
`
const Pre = styled.div`
	margin-bottom: 4px;
`
const Question = styled.div`
	
`
const Close = styled.button`
	position: absolute;
	top: 22px;
	right: 24px;	
`
const Cross = styled(CrossLarge)`
	width: 15px;
`
const Bottom = styled.div`
	padding: 20px 20px 0px;
	background-color: var(--highlighter-pink-light);
	border-top: 1px solid var(--black);
	opacity: ${props => props.success ? '0' : '1'};
	transition: opacity 0.3s;
	position: ${props => props.success ? 'absolute' : 'relative'};
	bottom: 0;
	left: 0;
	right: 0;
`
const Form = styled(ContactForm)`
	
`
const Success = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
`

PopUp.propTypes = {
	className: PropTypes.string
}

export default PopUp