import React from "react"
import styled from '@emotion/styled'
import { connectSearchBox } from "react-instantsearch-dom"
import { Search } from '~components/Svg'
import { mobile, tablet } from '~styles/global'

export default connectSearchBox(
  ({ refine, currentRefinement, className, onFocus, isHeader, onSubmit }) => {

		const handleSubmit = e => {
			e.preventDefault()
			if(onSubmit){
				onSubmit(e)
			}
		}

		return (
			<form className={className} onSubmit={handleSubmit}>
				<SearchWrapper className={isHeader ? '' : 'dummy'}>
					<SearchField
						isHeader={isHeader}
						className="SearchInput"
						type="text"
						placeholder={isHeader ? 'What are you looking for?' : 'Search'}
						aria-label="Search"
						onChange={e => refine(e.target.value)}
						value={currentRefinement}
						onFocus={onFocus}
						autoFocus={isHeader}
					/>
					<SearchIcon onClick={handleSubmit} isHeader={isHeader}>
						<Search />
					</SearchIcon>
					<SearchIcon />
				</SearchWrapper>
			</form>
		)
	}
)

const SearchWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	${tablet}{
		align-items: start;
	}
`

const SearchField = styled.input`
	display: block;
	width: 100%;
	background: none;
	/* border-bottom: ${props => props.isHeader ? '1px dashed' : '1px solid'}; */
	border-bottom: 1px solid;
	/* text-transform: ${props => props.isHeader ? 'none' : 'inherit'};
	font-family: ${props => props.isHeader ? 'Times Now' : 'inherit'};
	font-size: ${props => props.isHeader ? '80px' : 'inherit'}; */
	font-family: 'Times Now';
	font-size: 80px;
	${tablet}{
		font-size: 28px;
		padding-bottom: 10px;
	}
	&:focus {
		outline: none;
		border-bottom: 1px dashed;
	}
	&::placeholder{
		color: var(--black);
	}
`

const SearchIcon = styled.button`
	position: absolute;
	right: 0;
	width: ${props => props.isHeader ? '50px' : '28px'};
	display: block;
	bottom: ${props => props.isHeader ? 'auto' : '10px'};
	${tablet}{
		width: 25px;
		bottom: auto;
	}
`