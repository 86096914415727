import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { useSiteState } from '~context/siteContext'
import Cookies from 'js-cookie'
import { Link } from 'gatsby'
import Button from '~components/Button'
import useSiteSettings from '~utils/useSiteSettings'
import resolveLink from '~utils/resolveLink'

const CookiesPopUp = ({ className }) => {

	const { cookiesMessage, cookiesReadMoreLink, acceptText } = useSiteSettings()

	const [siteState, setSiteState] = useSiteState()


	useEffect(() => {
		if(!Cookies.get('cookiesPopUpClosed')){
			setSiteState(prevState => ({
				...prevState,
				cookiesPopUp: true
			}))
    }
	}, [])

	const close = () => {
		Cookies.set('cookiesPopUpClosed', true, { expires: 7 })
		setSiteState(prevState => ({
			...prevState,
			cookiesPopUp: false
		}))
	}


	return (
		<Wrap className={className} show={siteState.cookiesPopUp}>
			<TextContainer>
			<Text className='caps'>
				{cookiesMessage}
			</Text>
			<ReadMore
				className='highlight-s' 
				to={resolveLink(cookiesReadMoreLink?.to)}
			>
				Read More
			</ReadMore>
			</TextContainer>
			<Button borderButton onClick={() => close()}>
				{acceptText}
			</Button>
		</Wrap>
	)
}

const Wrap = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: var(--yellow);
	z-index: 2;
	min-height: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: ${props => props.show ? 'translateY(0)' : 'translateY(100%)'};
	opacity: ${props => props.show ? '1' : '0'};
	transition: opacity 0.3s, transform 0.3s;
	${mobile}{
		flex-direction: column;
		padding: 20px 15px;;
	}
`
const TextContainer = styled.div`
	${mobile}{
		text-align: center;
		margin-bottom: 16px;
	}
`
const Text = styled.span`
	margin-right: 4px;
`
const ReadMore = styled(Link)`
	position: relative;
	margin-right: 30px;
	:after{
		position: absolute;
		content: '';
		bottom: 0;
		left: 0;
		right: 0;
		border-bottom: 1px solid;
	}
	${mobile}{
		margin-right: 0;
		margin-bottom: 12px;
	}
`

CookiesPopUp.propTypes = {
	className: PropTypes.string
}

export default CookiesPopUp